<template>
  <div>
    <StaffAdminPageTableControl class="mb-5"></StaffAdminPageTableControl>
    <AppPageCard>
      <template #card-title>
        {{ $t("EDUGENERAL.MESSAGE.STAFFROLE") }}
      </template>
      <template #card-toolbar>
        <div
          class="btn btn-icon btn-light btn-hover-primary mr-3"
          v-on:click="refresh"
          v-b-tooltip.hover
          :title="$t('COMMON.TOOLTIP.REFRESH')"
        >
          <span class="svg-icon svg-icon-lg svg-icon-primary">
            <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
          </span>
        </div>
      </template>
      <StaffAdminPageTable :reload="reload"></StaffAdminPageTable>
    </AppPageCard>
  </div>
</template>

<script>
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import StaffAdminPageTable from "@/modules/school/components/staff/admin/StaffAdminPageTable";
import StaffAdminPageTableControl from "@/modules/school/components/staff/admin/StaffAdminPageTableControl";
export default {
  name: "StaffAdminPage",
  components: { StaffAdminPageTableControl, StaffAdminPageTable, AppPageCard },
  data() {
    return {
      reload: false,
    };
  },
  methods: {
    refresh() {
      this.reload = !this.reload;
    },
  },
};
</script>

<style scoped></style>
